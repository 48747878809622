export {
  debounce,
  deepSet,
  deepGet,
  deepFreeze,
  typeOf,
  formatDate,
  formatNum
} from '@krknet/fe/helpers'

export { vSwitch } from '@krknet/fe/components'

export const onClickaway = {
  beforeMount (el, binding, vnode) {
    const vm = vnode.context
    const callback = binding.value

    let initialMacrotaskEnded = false
    setTimeout(() => { initialMacrotaskEnded = true }, 0)

    el._vue_clickaway_handler = ev => {
      const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined)
      if (initialMacrotaskEnded && (path ? path.indexOf(el) < 0 : !el.contains(ev.target))) return callback.call(vm, ev)
    }

    setTimeout(() => {
      const context = el.closest('.clickContainer') || document.documentElement
      context.addEventListener('click', el._vue_clickaway_handler, false)
    }, 100)
  },
  unmounted (el) {
    const context = el.closest('.clickContainer') || document.documentElement
    context.removeEventListener('click', el._vue_clickaway_handler, false)
    delete el._vue_clickaway_handler
  }
}

export const focusDirective = {
  mounted (el, binding) {
    if (binding.value !== false) el.focus()
  }
}

export const copyToClipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}
