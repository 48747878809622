<template>
  <form
    class="flex print:invisible flex-1 items-center"
    action="/articles/"
    method="GET"
  >
    <div class="group py-0.5 px-4 rounded-lg duration-200 bg-gray-100/0 focus-within:bg-gray-100/100 hover:bg-gray-100/100 transition-bg">
      <div class="relative text-gray-400 focus-within:text-gray-700 transition duration-200">
        <div class="flex absolute inset-y-0 left-0 items-center pointer-events-none"><i class="far fa-search" /></div>
        <input
          ref="input"
          v-model="input"
          class="block py-1 pr-7 pl-7 w-full h-full text-sm placeholder-gray-400 focus:placeholder-gray-300 text-gray-900 bg-transparent border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
          :placeholder="`Suche...`"
          type="search"
          autocomplete="off"
          spellcheck="false"
          tabindex="-1"
          name="s"
        >
        <div class="hidden group-hover:block">
          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <button
              v-if="input.length"
              type="submit"
              class="flex absolute inset-y-0 right-0 items-center text-gray-600 hover:text-gray-900 transition duration-100"
            >
              <i class="far fa-arrow-right" />
            </button>
            <div
              v-else
              class="flex absolute inset-y-0 right-0 items-center text-xs text-gray-400 transition duration-100 pointer-events-none"
            >Strg + K</div>
          </transition>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import { debounce } from '@/helpers'

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches

  export default {
    name: 'GlobalSearch',
    data () {
      return {
        input: ''
      }
    },
    created () {
      document.addEventListener('keydown', e => {
        if (!e.ctrlKey || e.key !== 'k') return
        this.$refs.input.focus()
        e.preventDefault()
      })
      this.clearInput = debounce(() => { this.input = '' }, 1000)
      if (window.location.pathname !== '/search/' && (isStandalone || window.__version === 'dev')) this.listenToScan()
    },
    methods: {
      listenToScan () {
        document.addEventListener('keypress', e => {
          if (e.target.tagName.toLowerCase() === 'input') return
          if (e.key === 'Enter' && this.input) window.location = `/search/?s=${this.input}`
          if (e.key !== 'Enter') this.input += `${e.key}`
        }, false)
      }
    }
  }
</script>
