<template>
  <div class="flex fixed inset-0 z-50 justify-center sm:justify-end items-end sm:items-start sm:p-6 py-6 px-4 pointer-events-none">
    <!--
      Entering: "transform ease-out duration-300 transition"
        From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        To: "translate-y-0 opacity-100 sm:translate-x-0"
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="w-full max-w-sm bg-white rounded-sm border border-red-500 shadow-lg pointer-events-auto">
      <div class="overflow-hidden rounded-sm ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <svg
                class="w-6 h-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
            <div class="flex-1 pt-0.5 ml-3 w-0">
              <p class="text-sm font-medium leading-5 text-red-600" v-text="title" />
              <p class="mt-1 text-sm leading-5 text-red-600" v-text="text" />
            </div>
            <div class="flex flex-shrink-0 ml-4">
              <button
                class="inline-flex text-red-500 focus:text-red-700 transition duration-150 ease-in-out focus:outline-none"
                @click="close"
              >
                <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VAlert',
    props: {
      title: { type: String, required: true },
      text: { type: String, required: true }
    },
    emits: ['close'],
    mounted () {
      this.timeout = setTimeout(() => this.close(), 3000)
    },
    beforeUnmount () {
      clearTimeout(this.timeout)
    },
    methods: {
      close () {
        this.$emit('close')
      }
    }
  }
</script>
