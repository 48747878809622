<template>
  <div class="relative" @keydown.escape="closeDropdown">
    <template v-if="entry.subs && entry.subs.length > 0">
      <button
        class="group hidden sm:block sm:py-1.5 px-1 my-1 sm:my-0 mx-1 sm:border-b-2 border-l-2 sm:border-l-0 border-transparent transition duration-75"
        :class="isActive ? 'border-primary-600 text-gray-800' : 'text-gray-700 hover:text-gray-900'"
        :title="entry.title"
        @click="toggleDropdown"
      >
        <div class="py-1.5 px-2 group-hover:bg-gray-100 rounded-lg">
          <i class="opacity-50 group-hover:opacity-100 fal fa-fw" :class="entry.icon" />
          <span
            v-if="!entry.isShort"
            class="inline-block sm:hidden lg:inline-block ml-2 font-medium capitalize"
            v-text="entry.title"
          />
          <svg
            class="inline-block ml-1 w-5 h-5 opacity-50"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </button>
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="scale-95 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="scale-100 opacity-100"
        leave-to-class="scale-95 opacity-0"
      >
        <div
          v-if="isOpen"
          v-on-clickaway="closeDropdown"
          class="absolute z-50 rounded-md shadow-lg transform origin-top-right"
          style="min-width: 14rem;"
          :class="`${orientation}-0`"
        >
          <div class="overflow-hidden py-1 bg-white rounded-b-md border">
            <a
              v-for="sub of entry.subs"
              :key="sub.url"
              class="group block py-1 px-1 w-full text-sm focus:text-gray-900 focus:bg-gray-100 border-l-2 border-transparent focus:outline-none"
              :class="activePath === sub.url ? 'border-primary-600 text-gray-800' : 'text-gray-700 hover:text-gray-900'"
              :href="sub.active === false ? '#' : sub.url"
              :title="sub.title"
            >
              <div class="py-1.5 px-2 w-full group-hover:bg-gray-100 rounded transition duration-75">
                <i
                  class="group-hover:opacity-100 transition duration-75 fal fa-fw"
                  :class="[sub.icon, activePath !== sub.url && 'opacity-50']"
                />
                <span
                  class="inline-block lg:inline-block ml-2 font-medium capitalize"
                  v-text="sub.title"
                />
              </div>
            </a>
          </div>
        </div>
      </transition>
      <a
        v-for="sub of entry.subs"
        :key="sub.url"
        class="group block sm:hidden sm:py-1.5 px-1 my-1 sm:my-0 mx-1 sm:border-b-2 border-l-2 sm:border-l-0 border-transparent"
        :class="activePath === sub.url ? 'border-primary-600 text-gray-800' : 'text-gray-700 hover:text-gray-900'"
        :href="sub.active === false ? '#' : sub.url"
        :title="sub.title"
      >
        <div class="py-1.5 px-2 group-hover:bg-gray-100 rounded-lg transition duration-75">
          <i
            class="group-hover:opacity-100 transition duration-75 fal fa-fw"
            :class="[sub.icon, activePath !== sub.url && 'opacity-50']"
          />
          <span class="inline-block sm:hidden lg:inline-block ml-2 font-medium capitalize">{{ entry.title }}: {{ sub.title }}</span>
        </div>
      </a>
    </template>
    <a
      v-else
      class="group block sm:py-1.5 px-1 my-1 sm:my-0 mx-1 sm:border-b-2 border-l-2 sm:border-l-0 border-transparent"
      :class="isActive ? 'border-primary-600 text-gray-800' : 'text-gray-700 hover:text-gray-900'"
      :href="entry.active === false ? '#' : entry.url"
      :title="entry.title"
    >
      <div class="py-1.5 px-2 group-hover:bg-gray-100 rounded-lg transition duration-75">
        <i
          class="group-hover:opacity-100 transition duration-75 fal fa-fw"
          :class="[entry.icon, !isActive && 'opacity-50']"
        />
        <span
          class="inline-block sm:hidden lg:inline-block ml-2 font-medium capitalize"
          v-text="entry.title"
        />
      </div>
    </a>
  </div>
</template>

<script>
  import { onClickaway } from '@/helpers'

  export default {
    name: 'NavEntry',
    directives: { onClickaway },
    props: {
      entry: { type: Object, required: true },
      activePath: { type: String, required: true },
      orientation: { type: String, required: false, default: 'left' }
    },
    data () {
      return {
        isOpen: false
      }
    },
    computed: {
      isActive () {
        return this.entry.exact ? (this.activePath === this.entry.url) : this.activePath.startsWith(this.entry.url)
      }
    },
    methods: {
      toggleDropdown () {
        this.isOpen = !this.isOpen
      },
      closeDropdown () {
        this.isOpen = false
      }
    }
  }
</script>
