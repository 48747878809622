<!-- vModal 2022-07-01 -->

<template>
  <transition name="modal">
    <div
      class="flex fixed inset-0 flex-col justify-center items-center modal-mask"
      @click="close"
    >
      <p class="sm:hidden absolute top-0 mt-2 text-sm text-white">Fenster schließen</p>
      <div class="py-4 px-2 sm:px-4 pt-10 sm:pt-4 max-w-full max-h-full" @wheel.stop>
        <div
          class="flex overflow-hidden relative flex-col max-w-full max-h-full bg-white rounded-sm shadow-lg modal-container clickContainer"
          :style="{ width, height }"
          @click.stop
        >
          <slot name="overlays" />
          <div class="py-2 px-4 border-b modal-header">
            <slot name="header" />
          </div>
          <div class="overflow-y-auto relative flex-grow py-2 px-4 max-h-full modal-main">
            <slot />
          </div>
          <div class="flex flex-wrap py-1 px-4 -mx-2 border-t modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/component-definition-name-casing
    name: 'vModal',
    props: {
      width: { type: String, required: false, default: '36rem' },
      height: { type: String, required: false }
    },
    emits: ['close'],
    created () {
      const body = window.document.body
      const scrollPos = window.pageYOffset
      const hasScrollbar = window.innerWidth > document.documentElement.clientWidth
      const classes = ['fixed', 'w-full', 'overflow-y-scroll']
      const newlySet = classes.map(d => body.classList.contains(d) || d).filter(d => d !== true)
      if (hasScrollbar) {
        classes.map(d => body.classList.add(d))
        body.style.marginTop = `-${scrollPos}px`
      }

      const escapeHandler = e => { if (e.key === 'Escape') this.close() }
      document.addEventListener('keydown', escapeHandler)
      this.unregister = () => {
        document.removeEventListener('keydown', escapeHandler)
        if (hasScrollbar) {
          body.style.marginTop = null
          newlySet.map(d => body.classList.remove(d))
          window.scrollTo(0, scrollPos)
        }
      }
    },
    beforeUnmount () {
      this.unregister()
    },
    methods: {
      close () { this.$emit('close') }
    }
  }
</script>

<style>
  .modal-mask {
    z-index: 51;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
    backdrop-filter: blur(3px);
  }

  .modal-main {
    min-height: 10rem;
  }

  .modal-enter-from {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-header:empty {
    display: none;
  }

  .modal-footer:empty {
    display: none;
  }
</style>
