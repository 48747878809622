<!-- vWarning 2022-07-01 -->

<template>
  <v-modal @close="abort">
    <div class="py-4 px-2">
      <div class="sm:flex sm:items-start">
        <div class="flex flex-shrink-0 justify-center items-center mx-auto sm:mx-0 w-12 sm:w-10 h-12 sm:h-10 bg-red-100 rounded-full">
          <svg
            class="w-6 h-6 text-red-600"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div class="mt-3 sm:mt-0 sm:ml-4 text-center sm:text-left">
          <h3 class="text-lg font-medium leading-6 text-gray-900" v-text="title" />
          <div class="mt-2">
            <p class="text-sm leading-5 text-gray-500"><slot /></p>
          </div>
        </div>
      </div>
      <div class="sm:flex sm:flex-row-reverse mt-6">
        <button ref="allow" class="block py-2 mx-2 btn btn-alert" @click="allow">Fortfahren</button>
        <button class="block py-2 mx-2 btn btn-tertiary" @click="abort">Abbrechen</button>
      </div>
    </div>
  </v-modal>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/component-definition-name-casing
    name: 'vWarning',
    props: {
      title: { type: String, required: true }
    },
    emits: ['abort', 'allow'],
    mounted () {
      if (this.$refs.allow) this.$refs.allow.focus()
    },
    methods: {
      abort () { this.$emit('abort') },
      allow () { this.$emit('allow') }
    }
  }
</script>
