<template>
  <div class="sm:static w-full" :class="{ 'fixed': isMenuShown }">
    <header class="sticky print:static sm:static top-0 z-10 bg-white border-b shadow print:shadow-none sm:shadow-none">
      <div class="container px-4 mx-auto">
        <div class="hidden sm:flex justify-between w-full">
          <search />
          <a
            href="/"
            class="py-3 mx-2 text-xl hover:text-gray-900 transition duration-100"
          ><h1>{{ customer }} Inventur {{ year }}</h1></a>
          <div class="flex-1" />
        </div>
        <div class="flex sm:hidden w-full">
          <div class="flex-1" />
          <a href="/" class="flex-1 py-2 mx-2 text-xl text-center whitespace-nowrap"><h1>Inventur {{ year }}</h1></a>
          <div class="flex flex-1 justify-end items-center">
            <button
              class="print:hidden py-2 px-4 -mx-2 focus:outline-none fas fa-bars"
              @click="isMenuShown = !isMenuShown"
            />
          </div>
        </div>
      </div>
    </header>
    <nav
      class="print:hidden sm:block overflow-scroll sm:overflow-visible fixed sm:sticky sm:top-0 z-10 w-full max-h-full bg-white shadow"
      :class="{ 'hidden': !isMenuShown }"
    >
      <div class="container px-4 mx-auto">
        <div class="flex flex-col sm:flex-row -mx-1 whitespace-nowrap border-t-2 border-transparent">
          <nav-entry
            v-for="entry of leftEntries"
            :key="entry.url"
            :activePath="activePath"
            :entry="entry"
            orientation="left"
          />
          <div class="flex-grow border-t sm:border-transparent" />
          <nav-entry
            v-for="entry of rightEntries"
            :key="entry.url"
            :activePath="activePath"
            :entry="entry"
            orientation="right"
          />
        </div>
      </div>
      <div
        class="block sm:hidden fixed w-full h-screen bg-black bg-opacity-40 bg-blur-5"
        @click="isMenuShown = false"
      />
    </nav>

    <div v-if="title" class="container print:hidden py-4 px-4 mx-auto mt-2">
      <div class="flex my-2 max-w-full">
        <div
          v-if="pageIcon"
          class="flex flex-shrink-0 justify-center items-center my-px mr-3 w-10 h-10 text-gray-800 bg-gray-200 rounded-full"
        >
          <i class="far fa-fw" :class="pageIcon" />
        </div>
        <div class="flex-grow leading-tight">
          <h2 class="text-lg" v-text="pageTitle" />
          <p class="text-gray-500" v-text="desc" />
        </div>
        <slot name="header" />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
  import NavEntry from './navEntry.vue'
  import Search from './search.vue'

  export default {
    name: 'PageContainer',
    components: { NavEntry, Search },
    props: {
      title: { type: String, required: false },
      icon: { type: String, required: false },
      desc: { type: String, required: false, default: '' }
    },
    data () {
      return {
        activePath: window.location.pathname,
        isMenuShown: false,
        customer: window.customer,
        year: window.year,
        leftEntries: [
          { url: '/', title: 'Status', icon: 'fa-bullseye', exact: true },
          { url: '/articles/', title: 'Artikel', icon: 'fa-boxes' },
          { url: '/counts/', title: 'Erfassungen', icon: 'fa-stream' },
          { url: '/suspicious/', title: 'Auffälligkeiten', icon: 'fa-balance-scale-right' },
          { url: '/problems/', title: 'Probleme', icon: 'fa-exclamation-triangle' }
        ],
        rightEntries: [
          { url: '/import/', title: 'Import', icon: 'fa-file-upload' },
          { url: '/export/', title: 'Export', icon: 'fa-file-download' },
          { url: '/setup/', title: 'Einrichtung', icon: 'fa-wrench' }
        ]
      }
    },
    computed: {
      activeEntry () {
        return [this.leftEntries, this.rightEntries].flat().find(d => d.exact ? d.url === this.activePath : this.activePath.startsWith(d.url)) || {}
      },
      pageIcon () {
        return this.icon || this.activeEntry.icon
      },
      pageTitle () {
        return this.title || this.activeEntry.title
      },
      fullTitle () {
        return `${this.pageTitle} - ${this.customer} Inventur ${this.year}`.replace(/\w\S*/g, d => d.charAt(0).toUpperCase() + d.substring(1))
      }
    },
    watch: {
      fullTitle: {
        handler (newValue) {
          document.title = newValue
        },
        immediate: true
      },
      activeEntry: {
        handler (newValue) {
          if (!newValue.url) return
          const sub = (newValue.subs || []).find(d => d.url === this.activePath)
          const title = sub ? `${newValue.title}: ${sub.title}` : newValue.title
          const capitalize = d => d.split(' ').map(e => e.charAt(0).toUpperCase() + e.slice(1)).join(' ')
          document.title = `${capitalize(title)} - ${this.customer} Inventur ${this.year}`
        },
        immediate: true
      }
    }
  }
</script>
