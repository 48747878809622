<!-- vToggle 2022-06-28 -->

<template>
  <label
    tabindex="0"
    class="inline-flex relative flex-shrink-0 items-center cursor-pointer focus:outline-none"
    :class="small ? 'w-9 h-4' : 'w-10 h-5'"
  >
    <input type="checkbox" class="hidden" :value="modelValue" @input="toggle">
    <span
      aria-hidden="true"
      class="absolute mx-auto rounded-full transition-colors duration-200 ease-in-out"
      :class="[modelValue ? 'bg-primary-600' : 'bg-gray-200', small ? 'w-8 h-3' : 'w-9 h-4']"
    />
    <span
      aria-hidden="true"
      class="inline-block relative bg-white rounded-full shadow transition duration-200 ease-in-out transform"
      :class="[modelValue ? (small ? 'translate-x-4' : 'translate-x-5') : 'translate-x-0', small ? 'w-4 h-4' : 'w-5 h-5']"
    >
      <span
        class="flex absolute inset-0 justify-center items-center w-full h-full transition-opacity"
        :class="modelValue ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'"
      >
        <svg class="w-3 h-3 text-gray-400" fill="none" viewBox="0 0 12 12">
          <path
            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <span
        class="flex absolute inset-0 justify-center items-center w-full h-full transition-opacity"
        :class="modelValue ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'"
      >
        <svg class="w-3 h-3 text-primary-600" fill="currentColor" viewBox="0 0 12 12">
          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
        </svg>
      </span>
    </span>
  </label>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/component-definition-name-casing
    name: 'vToggle',
    props: {
      modelValue: { type: Boolean, required: true },
      small: { type: Boolean, required: false, default: false }
    },
    emits: ['update:modelValue'],
    methods: {
      toggle () {
        this.$emit('update:modelValue', !this.modelValue)
      }
    }
  }
</script>
